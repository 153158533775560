
import { defineComponent, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'
import { AxiosResponse } from 'axios'
import { ExportExcelService } from '@/services/ExportExcelService'
import { ImportTaskResult } from '@/model/index'

/*

 failureCount: number;
  successCount: number;
  failiureDetails: Array<ImportFailureDetail>;
*/
export default defineComponent({
  setup() {
    name: 'data-import'
    const projectInfoFileInput = ref<HTMLInputElement>()
    const communicationsFileInput = ref<HTMLInputElement>()
    const app = getCurrentInstance()
    const communicationImprotResult = ref<ImportTaskResult>()
    const projectInfoImprotResult = ref<ImportTaskResult>()
    const importInprogress = ref(false)


    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }
    
    function importCommunications() {
      if(!communicationsFileInput.value){
        return
      }
      
      importInprogress.value = true
      communicationImprotResult.value = undefined
      const formData = new FormData();
      if(communicationsFileInput.value?.files && communicationsFileInput.value?.files?.length > 0) {
        formData.append("data", communicationsFileInput.value.files[0]);
          axiosService.post('api/import/communications', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then((res: AxiosResponse<ImportTaskResult>) => {
            communicationImprotResult.value = res.data
            importInprogress.value = true
          }).catch(error => {
            if (error.response && error.response.status && error.response.status === 400) {
              handleFailedImport(error.response.data)
              importInprogress.value = false
            }
          })
      } else {
        toast({
          message: '请选择需要上传的文件',
          color: 'danger',
          closeable: true,
          duration: 2000
        })
      }
    }

    function importProjectInfos() {
      if(!projectInfoFileInput.value){
        return
      }
      
      projectInfoImprotResult.value = undefined
      importInprogress.value = true
      const formData = new FormData();
      if(projectInfoFileInput.value?.files && projectInfoFileInput.value?.files?.length > 0) {
        formData.append("data", projectInfoFileInput.value.files[0]);
          axiosService.post('api/import/projectinfos', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then((res: AxiosResponse<ImportTaskResult>) => {
            projectInfoImprotResult.value = res.data
            importInprogress.value = false
          }).catch(error => {
            if (error.response && error.response.status && error.response.status === 400) {
              handleFailedImport(error.response.data)
              importInprogress.value = false
            }
          })
      } else {
        toast({
          message: '请选择需要上传的文件',
          color: 'danger',
          closeable: true,
          duration: 2000
        })
      }
    }
    
    function handleFailedImport(data: any) {
      if (data.versionNotMatched || data.templateNotMatched) {
        toast({
          message: '导入模板校验失败，建议重新下载模板填写！',
          color: 'danger',
          closeable: true,
          duration: 2000
        })
      }

      if (data.noData) {
        toast({
          message: '未检测到模板内有填写数据！',
          color: 'danger',
          closeable: true,
          duration: 2000
        })
      }
    }

    function downloadFailuredDetails(improtReuslt: ImportTaskResult) {
      if(!improtReuslt) {
        return
      }

      const exportService = new ExportExcelService(improtReuslt.failiureDetails.map(c => ({
        serialNo: c.serialNo,
        companyName: c.companyName,
        details: c.errorResult.join('\r\n'),
      })))
      exportService.Export2Excel("导入失败记录", [
        { header: '序号', key: 'serialNo', width: 10 },
        { header: '公司名称', key: 'companyName', width: 50 },
        { header: '失败原因', key: 'details', width: 70 },
      ])
    }

    return  {
      projectInfoFileInput,
      communicationsFileInput,
      importCommunications,
      importProjectInfos,
      communicationImprotResult,
      projectInfoImprotResult,
      downloadFailuredDetails,
      importInprogress,
    }
  },
})
